// Images file for 'find.dpasearch.com'.
// The export of 'Favicon' and 'Logo' must match those names exactly.
// Use 'images.js' in the 'harpquiz' directory as a guide.

import Favicon from './assets/favicon.ico';
import HeaderBackground from './assets/header-bg.jpg';
import SliderArrows from './assets/slider-handle.png';
import DollarSign from './assets/dollar-sign.png';
import Dropdown from '../../../../src/images/dropdown_carat.png';
import Yes from './assets/checkmark.png';
import No from './assets/x.png';
import Veteran from './assets/veteran.png';
import Law from './assets/law-enforcement.png';
import FireFighter from './assets/firefighter.png';
import Health from './assets/doctor.png';
import Educator from './assets/teacher.png';
import Other from './assets/other.png';
import Home from './assets/home.png';
import Townhouse from './assets/townhouse.png';
import Condo from './assets/condo.png';
import Mobile from './assets/mobilehome.png';

export default {
  Favicon,
  Dropdown,
  HeaderBackground,
  SliderArrows,
  DollarSign,
  Yes,
  No,
  Veteran,
  Law,
  FireFighter,
  Health,
  Educator,
  Other,
  Home,
  Townhouse,
  Condo,
  Mobile,
};
