// Styles file for `find.dpasearch.com`.

// Styles written here will override the corresponding component
// This is a stylesheet example of `Harpquiz`.

import { css, keyframes } from 'styled-components';
import images from './images';

// Theming
const DEFAULT_COLORS = {
  questionTitle: '#4d4d4d',
  text: '#fff',
  headline: '#000',
  link: '#7f7f7f',
  choiceInput: '#00b06c',
  choiceInputHover: '',
  submitButtonBackground: '#00b06c',
  submitButtonText: '#fff',
  headerBackground: '',
  mainBackground: '',
  surveyBackground: '',
  footerBackground: '#4d4d4d',
  progressBarBackground: '#f5f5f5',
  progressBarFill: '#337ab7',
  sliderFill: '#f5f5f5',
};

const DEFAULT_FONT = {
  questionTitle: '22px',
  text: '16px',
  headline: '28px',
  submitButton: '14px',
  tcpa: '11px',
  footerText: '12px',
};

const DEFAULT_SIZE = {
  headerHeight: '90px',
  headerMobileHeight: '',
  submitButtonWidth: '205px',
  inputFieldHeight: '60px',
  inputFieldWidth: '300px',
  inputFieldMobileWidth: '',
};

// Header
const HeaderContainer = css`
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: ${DEFAULT_SIZE.headerHeight}; /* Don't forget to change the min-height calculation for SurveyBackground */
  max-width: 100%;
  width: 100vw;
  min-height: 330px;
  padding: 15px 20px;
  /* margin: auto; */
  /* background: ${DEFAULT_COLORS.headerBackground}; */
  background: url(${images.HeaderBackground}) 50% 50%/cover no-repeat;
  flex-direction: column;

  @media (max-width: 800px) {
    padding: 5px;
    min-height: 250px;
  }
`;

const HeaderImage = css`
  height: auto;
  max-width: 250px;
  max-height: 100%;
  margin-bottom: 0;

  @media (max-width: 800px) {
    width: 100%;
    max-width: 400px;
  }
`;

// Only used when sites background color is needed
// specificy "background-color" because of the blurred-rates image.
const AppWrapper = css`
  background-color: ${DEFAULT_COLORS.mainBackground} !important;
`;

// Main Background
const SurveyBackground = css`
  /* min-height: calc(100vh - 92px); */
  width: 100vw;
  margin: auto;

  background-color: ${DEFAULT_COLORS.surveyBackground};
`;

const SurveyContainer = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #f3f5f8;
  margin: auto;
  min-height: 68vh;
  justify-content: flex-start;

  div {
    &#alternate-container {
      /* Used if you need to overwrite the width of the hidden container */
      /* width: 100%; */
    }
  }
`;

const QuestionNumber = css`
  font-size: 14px;
  width: 100%;
  max-width: ${DEFAULT_SIZE.inputFieldWidth};
  margin-bottom: 5px;
`;

const FormContainer = css`
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 0;
  padding: 0;
  width: 100%;
  padding-top: 30px;
  max-width: 700px;
  margin: auto;

  &.initial {
    flex-direction: row;
    padding-top: 0;
    max-width: 100%;
    background-color: #fff;

    input,
    select {
      height: 40px;
      ::-webkit-input-placeholder {
        color: transparent;
      }
    }

    #button-container {
      background: #f3f5f8;
      width: 100%;
      max-width: 100%;
      margin: 0;
      justify-content: center;
    }

    > section {
      flex: 1 1 auto;
      max-width: 50%;
      width: 420px;
      padding: 60px 0;

      h4,
      div {
        width: 420px;
        max-width: 100%;
      }
      > p {
        width: 420px;
        max-width: 100%;
        margin: 0 auto 10px auto;
      }
    }

    > div {
      flex: 1;
      margin: auto;

      #next-button {
        position: relative;
        width: 310px;

        &::before {
          content: 'There are programs you may qualify for that currently match your selections';
          text-transform: none;
          color: #000;
          position: absolute;
          top: -70px;
          font-size: 14px;
          font-style: italic;
          font-weight: 300;
          font-family: 'IBM Plex Serif', serif;
        }

        &::after {
          content: "Let's get started";
          text-transform: uppercase;
          background: #00b06c;
          position: absolute;
          width: 100%;
          height: 100%;
          color: #fff;
          font-size: 12px;
          border-radius: 4px;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        @media (max-width: 800px) {
          width: 90%;
        }
      }
    }

    p {
      font-size: 11px;
      font-family: 'IBM Plex Serif', serif;
      align-self: flex-start;
      margin-bottom: 10px;
    }
    @media (max-width: 800px) {
      flex-direction: column;
      > section {
        max-width: 90%;
        margin: auto;
        padding-top: 20px;
      }
    }
  }
`;

const GlobalSealImg = css`
  max-width: 110px;
`;

// Containers
// Multiple choice
const ChoiceContainer = css`
  display: flex;
  align-items: center;
  box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.1);
  padding: 10px 40px;
  margin: 8px 0;
  transition: background-color 230ms ease;
  width: 100%;
  max-width: ${DEFAULT_SIZE.inputFieldWidth};
  height: ${DEFAULT_SIZE.inputFieldHeight};
  background-color: ${DEFAULT_COLORS.choiceInput};
  color: ${DEFAULT_COLORS.text};
  cursor: pointer;
  border-radius: 2px;
  justify-content: center;
  font-size: 18px;

  @media (max-width: 768px) {
    max-width: 750px;
    width: 90%;
    margin: 8px auto;
  }
`;

// Styles for selected radio button (multiple-choice)
const InputContainer = css`
  width: 100%;
  max-width: ${DEFAULT_SIZE.inputFieldWidth};
  position: relative;
  text-transform: uppercase;

  @media(max-width: 768px) {
    max-width: 750px;
  }

  > input:checked + label {
    /* background: url(${images.Checkmark}) 94% 50%/20px no-repeat
      ${DEFAULT_COLORS.choiceInputHover}; */
  }

  &.input-opt_served_military,
  &.input-Occupation,
  &.input-had-mortgage,
  &.input-property_type,
  &.input-home_found,
  &.input-agent_found {
    display: flex;
    justify-content: center;
    > input:checked + label {
      border: 5px solid #00b06c !important;
  }

    label {
      border-radius: 50%;
      height: 136px;
      width: 136px;
      display: flex;
      justify-content: center;
      color: transparent;
      box-shadow: 0 3px 8px 0 rgba(0,0,0,.1);
      border: 5px solid #fff;
      /* transition: all .3s ease-in-out; */

      &::after {
          text-transform: none;
          color: #000;
          position: absolute;
          bottom: -35px;
          font-size: 16px;
          font-weight: 400;
          font-family: 'Roboto', sans-serif;
          left: 0;
          width: 100%;
          text-align: center;
        }
    }

     /* Yes styling */
   > input[id='1'] + label {
      background: #ebedee url(${images.Yes}) 50% 50% / contain no-repeat;
      background-size: 60px;
      color: transparent;
      position: relative;
      border: 5px solid #fff;

      &::after {
          content: 'Yes';
        }
    }
    /* No styling */
    > input[id='0'] + label {
      background: #ebedee url(${images.No}) 50% 50% / contain no-repeat;
      background-size: 60px;
      color: transparent;
      position: relative;
      border: 5px solid #fff;

      &::after {
          content: 'No';
        }
    }
     /* Veteran styling */
    > input[id='military'] + label {
      background: #ebedee url(${images.Veteran}) 50% 50% / contain no-repeat;
      background-size: 40px;
      height: 90px;
      width: 90px;
      &::after {
          content: 'Veteran or Active Duty';
      }
    }
    > input[id='law enforcement'] + label {
      background: #ebedee url(${images.Law}) 50% 50% / contain no-repeat;
      background-size: 40px;
      height: 90px;
      width: 90px;
      &::after {
          content: 'Law Enforcement';
      }
    }
    > input[id='firefighter-emt'] + label {
      background: #ebedee url(${images.FireFighter}) 50% 50% / contain no-repeat;
      background-size: 40px;
      height: 90px;
      width: 90px;
      &::after {
          content: 'Firefigher or EMT';
      }
    }
    > input[id='healthcare provider'] + label {
      background: #ebedee url(${images.Health}) 50% 50% / contain no-repeat;
      background-size: 40px;
      height: 90px;
      width: 90px;
      &::after {
          content: 'Healthcare Provider';
      }
    }
    > input[id='educator'] + label {
      background: #ebedee url(${images.Educator}) 50% 50% / contain no-repeat;
      background-size: 40px;
      height: 90px;
      width: 90px;
      &::after {
          content: 'Educator';
      }
    }
    > input[id='Other'] + label {
      background: #ebedee url(${images.Other}) 50% 50% / contain no-repeat;
      background-size: 40px;
      height: 90px;
      width: 90px;
      &::after {
          content: 'Others';
      }
    }
    > input[id='Single Family'] + label {
      background: #ebedee url(${images.Home}) 50% 50% / contain no-repeat;
      background-size: 70px;
      &::after {
          content: 'Single Family';
      }
    }
    > input[id='townhouse'] + label {
      background: #ebedee url(${images.Townhouse}) 50% 50% / contain no-repeat;
      background-size: 70px;
      &::after {
          content: 'Townhouse';
      }
    }
    > input[id='mobile'] + label {
      background: #ebedee url(${images.Mobile}) 50% 50% / contain no-repeat;
      background-size: 70px;
      &::after {
          content: 'Mobile or Manufactured';
      }
    }
    > input[id='condo'] + label {
      background: #ebedee url(${images.Condo}) 50% 50% / contain no-repeat;
      background-size: 70px;
      &::after {
          content: 'Condo';
      }
    }
  }

  &.input-Occupation {
    width: 33%;
    margin-bottom: 40px;
  }
`;

// Input
const inputStyle = css`
  width: 100%;
  max-width: ${DEFAULT_SIZE.inputFieldWidth};
  height: ${DEFAULT_SIZE.inputFieldHeight};
  padding-left: 20px;
  border-radius: 3px;
  font-size: 16px;
  background: #fff;
  border: ${props =>
    props.border
      ? '1px solid red'
      : '1px solid #ededed'} !important; /* overrides initial setting*/

  ::-webkit-input-placeholder {
    text-align: center;
  }
  text-align: center;
`;

const InputField = css`
  ${inputStyle}
  border: ${props => props.border};

  @media (max-width: 800px) {
    width: 100%;
  }
`;

const StyledTextArea = css`
  ${inputStyle}
  border-radius: 5px;
  border: ${props => props.border};
`;

const AddressContainer = css`
  display: flex;
  flex-direction: column;
  max-width: ${DEFAULT_SIZE.inputFieldWidth};
  width: 100%;

  p {
    margin-bottom: 0;
    margin-top: 10px;
  }

  input {
    width: 100%;
    max-width: ${DEFAULT_SIZE.inputFieldWidth};
    margin: auto;
  }
`;

// Dropdown
const StyledSelect = css`
  ${inputStyle}
  margin: 0;
  border: 1px inset #fff;
  -webkit-appearance: none;
  background: url(${images.Dropdown}) 95% 50% no-repeat #fff;
  background-size: 20px;
`;

// Zip input
const StyledZip = css`
  ${inputStyle}
  margin-bottom: 12px;
  color: #555;
  border: ${props =>
    props.border
      ? '1px solid red'
      : '1px solid #000'} !important; /* overrides initial setting*/
  padding: 0 20px;
  text-align: center;
  position: relative;

  ::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const FinalInputContainer = css`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: ${DEFAULT_SIZE.inputFieldWidth};
  margin-bottom: 15px;

  &.final-input-container-first_name,
  &.final-input-container-last_name {
    width: 48%;
  }
  &.final-input-container-last_name {
    margin-left: auto;
  }
  &.final-input-container-address {
    > div > p {
      margin-bottom: 0;
    }
  }

  p:first-of-type {
    margin-bottom: -32px;
    margin-left: 20px;
    z-index: 9;
    color: #7f7f7f;
    font-size: 10px;
    text-transform: uppercase;
  }

  input,
  select {
    margin-top: 10px;
    margin-bottom: 5px;
    width: 100%;
    height: 50px;
    text-align: left;
    padding-top: 14px;
  }

  @media (max-width: 800px) {
    width: 95% !important;
    margin: 0 auto 15px;
  }
`;

// Hidden inputs
const HiddenContainer = css`
  display: flex;
  flex-direction: column;
  margin: 10px auto;
  width: 100%;
  margin-top: ${props => props.secondary && '0'};

  input {
    height: ${DEFAULT_SIZE.inputFieldHeight};
  }
`;

// Survey styles
const QuestionTitle = css`
  color: ${DEFAULT_COLORS.questionTitle};
  font-weight: 400;
  font-size: ${DEFAULT_FONT.questionTitle};
  line-height: 1;
  margin-bottom: 10px;
  text-align: center;
  width: 100%;

  &#questionTitle-first-frame {
    font-size: 22px;
    font-weight: 400;
    text-align: left;
    line-height: 1.5;

    @media (max-width: 800px) {
      font-size: 16px;
    }
  }
  @media (max-width: 800px) {
    width: 98%;
  }

  @media (max-width: 600px) {
    font-size: 18px;
  }
`;

const InputTitle = css`
  margin-bottom: 0;
`;

const Subtitle = css`
  text-align: center;
  margin-bottom: 10px;
  font-size: 13px;
  width: 98%;
`;

const TipContainer = css`
  text-align: center;
  background: transparent;
  padding: 10px;
  color: #4d4d4d;
  width: 80%;
  font-family: 'IBM Plex Serif', serif;
  font-size: 26px;
  max-width: 500px;
  position: relative;
  margin-bottom: 30px;

  &#tip-container-0 {
    font-size: 36px;
    max-width: 700px;

    @media (max-width: 800px) {
      font-size: 20px;
      margin-bottom: 0;
      margin-top: -60px;
    }

    &::after {
      content: 'Let us help you find yours';
      font-family: Roboto, sans-serif;
      position: absolute;
      color: #4d4d4d;
      font-size: 18px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 20px;
    }
  }

  @media (max-width: 800px) {
    font-size: 20px;
    margin-bottom: 0;
  }

  @media (max-width: 600px) {
    font-size: 18px;
  }
`;

const QuestionContainer = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  #final-frame-container {
    flex-wrap: wrap;
    flex-direction: row;
    display: flex;

    div {
      max-width: 100%;
    }

    /* div:nth-child(1),
    div:nth-child(2) {
      width: 48%;
    }
    div:nth-child(2) {
      margin-left: auto;
    } */
    input {
      max-width: 100%;
      ::-webkit-input-placeholder {
        color: transparent;
      }
    }
  }
`;

const QuestionBodyContainer = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
  width: 100%;
  justify-content: center;

  &.question-opt_served_military,
  &.question-Occupation,
  &.question-had-mortgage,
  &.question-property_type,
  &.question-home_found,
  &.question-agent_found {
    flex-direction: row;
  }

  &.question-Occupation {
    flex-wrap: wrap;
  }

  &.questionbody-down_payment_block {
    > input {
      background: #fff url(${images.DollarSign}) 15px 50%/10px no-repeat;
      text-align: left;
      padding-left: 40px;
    }
  }

  #email-container {
    position: relative;

    p:not(#error-message) {
      display: none;
    }

    input {
      text-align: left;
      ::-webkit-input-placeholder {
        color: transparent;
      }
    }

    &::before {
      content: 'Enter your email';
      text-transform: uppercase;
      color: #000;
      position: absolute;
      font-size: 9px;
      font-weight: 300;
      top: 7px;
      left: 20px;
      color: #7f7f7f;
    }
  }

  @media (max-width: 800px) {
    &.question-property_type {
      flex-wrap: wrap;
      > div {
        width: 100%;
        flex: 50%;
        margin-bottom: 40px;
      }
    }
  }
`;

// Slider
const StyledSlider = css`
  width: 100%;
`;

const SliderBox = css`
  width: 90%;
`;

const StyledThumb = css`
  background: url(${images.SliderArrows}) 50% 50% / contain no-repeat;
  border: 3px solid #fff;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
  position: relative;
  z-index: 1;
  transform: scale(1.1);
  margin-top: 16px;
`;

const DisplayNumber = css`
  font-size: ${({ primary }) => (primary ? '24px' : '16px')};
  margin-top: ${({ primary }) => (primary ? '30px' : '10px')};
  margin-bottom: 0;
  font-weight: 400;
  @media (max-width: 800px) {
    font-size: ${({ primary }) => (primary ? '32px' : '16px')};
  }
`;

const NumberContainer = css`
  display: flex;
  justify-content: space-between;
  width: inherit;
`;

const SliderContainer = css`
  width: inherit;
  border: 3px solid #fff;
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.1);
  background: #fff;
  height: 30px;
  margin-top: 20px;
  border-radius: 15px;
`;

const FillContainer = css`
  position: absolute;
  background: ${DEFAULT_COLORS.sliderFill};
  width: ${props => `${props.width}%`};
  height: 30px;
  margin-top: 20px;
  border-radius: 15px 0 0 15px;
`;

// ProgressBar
const ProgressContainer = css`
  margin: 0 auto 10px auto;
  width: 50vw;
  max-width: 400px;
  height: 20px;
  background: ${DEFAULT_COLORS.progressBarBackground};
  border-radius: 5px;
  overflow: hidden;
`;

// Progressbar animation
const ProgressBarStripes = keyframes`
  from {
    background-position: 50px 0;
  } to {
    background-position: 0 0;
  }
`;

const StatusBar = css`
  animation: ${ProgressBarStripes} 2s linear infinite;
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-size: 50px 50px;
  width: ${props => props.width};
  height: inherit;
  background-color: ${DEFAULT_COLORS.progressBarFill};
  transition: width 0.25s ease;
`;

// Used for the footer progressbar.
const FooterNumberStatus = css`
  /* position: absolute;
  left: 50%;
  top: 15px;
  margin-left: -52px;
  color: #fff;
  font-weight: 300;

  @media (max-width: 800px) {
    display: none;
  } */
`;

// buttons
const SubmitButton = css`
  margin: 15px 0;
  width: 100%;
  padding: 7px 5px;
  border-radius: 3px;
  background-color: ${DEFAULT_COLORS.submitButtonBackground};
  border: 0px;
  font-size: ${DEFAULT_FONT.submitButton};
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${DEFAULT_COLORS.submitButtonText};
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  text-transform: uppercase;
  width: 180px;
  height: 60px;

  &:hover {
    opacity: 0.9;
  }

  /* Final Submit button is different than the 'next' buttons */
  &#submit-button {
    width: 100%;
    font-size: 16px;
    font-weight: 500;
  }

  &.submit-button-24 {
    visibility: hidden;
    position: relative;

    &::after {
      content: 'ONTO THE LAST STEP!';
      visibility: initial;
      text-transform: none;
      color: #fff;
      position: absolute;
      font-size: 18px;
      font-weight: 400;
      width: 300px;
      background-color: ${DEFAULT_COLORS.submitButtonBackground};
      padding: 20px 5px;
      border-radius: 3px;
      /* font-family: 'IBM Plex Serif', serif; */
    }
  }
  @media (max-width: 800px) {
    width: 100%;
  }
`;

const BackButton = css`
  border: none;
  background: inherit;
  color: #00b06c;
  margin-bottom: 5px;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    background: none;
  }
`;

const ButtonContainer = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 94%;
  max-width: ${DEFAULT_SIZE.submitButtonWidth};
  margin: auto;

  &#final-button-container {
    width: 100%;
    max-width: 100%;
  }

  @media (max-width: 800px) {
    width: 95%;
    max-width: 100%;
  }
`;

// Footer
const FooterContainer = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 30px;
  background: ${DEFAULT_COLORS.footerBackground};
  color: #7f7f7f;
  max-width: 960px;
  width: 100%;
  margin: auto;

  p {
    margin-bottom: 10px;
    width: 100%;
  }

  a {
    color: ${DEFAULT_COLORS.link};
  }
`;

const DisclosureContainer = css`
  text-align: center;
  max-width: 960px;
  margin: 3vh auto;

  a {
    display: inline-block;
    margin-bottom: 20px;
    font-size: 12px;
  }
`;

const ModalButtonContainer = css`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-content: center;
  margin: 2vh auto;
  border-top: 1px solid #c9c9c9;
  padding-top: 1vh;

  span {
    color: ${DEFAULT_COLORS.link};
  }

  @media (max-width: 500px) {
    width: 98%;
  }
`;

const ModalButton = css`
  border: 0;
  background: inherit;
  color: ${DEFAULT_COLORS.link};
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

// Modal
const ModalBackground = css`
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 2;
`;
const ModalContainer = css`
  margin: auto;
  background: white;
  width: ${props => (props.primary ? '50vw' : '70vw')};
  height: ${props => (props.primary ? '60vh' : '80vh')};
  padding: 3vh 3vw;
  border-radius: 10px;
  overflow: auto;

  @media (max-width: 900px) {
    width: 90vw;
  }
`;

const ExitButton = css`
  top: ${props => (props.primary ? '19vh' : '9vh')};
  right: ${props => (props.primary ? '24vw' : '14vw')};
  position: absolute;
  border-radius: 50%;
  border: 1px solid #eee;
  font-size: 1.5em;
  cursor: pointer;
  transition: all 0.5s ease;
  height: 33px;
  width: 33px;
  background: #fff;

  &:hover {
    background: #969696;
    color: #fff;
  }

  @media (max-width: 900px) {
    right: 3vw;
  }
`;

const ModalInformation = css`
  display: flex;
  flex-direction: column;
  /* overflow: scroll; */
  line-height: 1.5em;

  br {
    line-height: 2em;
  }

  p {
    width: 100%;
  }

  @media (max-width: 600px) {
    font-size: 0.8em;
  }
`;

const BoldText = css`
  font-size: 11px;
  text-transform: uppercase;
  color: ${props => props.primary && '#C1794C'};
  text-align: ${props => props.primary && 'center'};
`;

const Text = css`
  color: #585858;
  font-size: 14px;
  font-weight: ${props => props.primary && '700'};
  margin-bottom: 5px;
`;

const FooterText = css`
  margin-top: ${props => props.primary && '30px'};
  white-space: pre-line;
  text-align: center;
  font-size: ${DEFAULT_FONT.footerText};
  color: ${({ primary }) => primary && '#767676'};
`;

const ImageList = css`
  margin: 0;
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
`;

const HeadlineContainer = css`
  text-align: center;
  margin: 30px auto;
  display: none;
`;

const HeadlineHeader = css`
  color: ${DEFAULT_COLORS.headline};
  font-size: ${DEFAULT_FONT.headline};
  font-weight: 500;
  margin-bottom: 10px;

  @media (max-width: 801px) {
    font-size: 22px;
    font-weight: 400;
  }
`;

const SubHeader = css`
  font-size: ${DEFAULT_FONT.text};
  font-weight: 200;
  color: ${DEFAULT_COLORS.headline};
  margin-bottom: 0;
`;

const SmallText = css`
  font-size: ${props => (props.secondary ? '0.9em' : '0.7em')};
  font-weight: ${props => (props.primary ? '700' : '200')};
  max-width: 500px;
  margin: auto;
`;

const ErrorText = css`
  color: #ee0000 !important;
  font-style: italic;
  text-align: center;
  font-size: 12px;
`;

const FinalDisclosureContainer = css`
  padding: 20px;
  background-color: #e3e3e3;
  color: #767676;
  border-radius: 4px;
  min-height: 20px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  max-width: 800px;
  margin: 20px auto;

  p,
  label {
    margin-bottom: 2px;
    font-size: ${DEFAULT_FONT.tcpa};
    color: #767676;
    line-height: 1.3;
  }
  @media (max-width: 800px) {
    width: 95%;
  }
`;

// Loading Spinner
const Bounce = keyframes`
  0%, 80%, 100% { 
    transform: scale(0);
  } 40% { 
    transform: scale(1.0);
  }
`;

const SpinnerContainer = css`
  margin: 100px auto 0;
  width: 70px;
  text-align: center;
`;

const Spinner = css`
  width: 18px;
  height: 18px;
  background-color: #5598c1;
  border-radius: 100%;
  display: inline-block;
  animation: ${Bounce} 1.4s infinite ease-in-out both;
  animation-delay: ${props => props.delay};
`;

const LoadingContainer = css`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
  z-index: 2;
`;

const SpinnerMainContainer = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: auto;
  width: auto;
  padding: 40px;
  background: #fff;
`;

const CongratsHolder = css`
  /* Style property not used in this survey */
`;

const CheckboxWrapper = css`
  display: flex;
  flex-direction: column;
  margin-top: 1vh;
`;

const CheckboxContainer = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 5px 0;
`;

const CheckboxLabel = css`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  > input {
    -webkit-appearance: none;
    height: 0;
    width: 0;
    margin-left: 5px;
  }

  > input[type='checkbox']:before {
    content: '';
    display: block;
    position: absolute;
    width: 22px;
    height: 22px;
    top: 0;
    left: 0;
    border: 1px solid #dedede;
    border-radius: 3px;
    background-color: white;
    box-shadow: inset #777 0 0 2px;
    margin: 4px;
  }
  > input[type='checkbox']:checked:after {
    content: '';
    display: block;
    width: 6px;
    height: 12px;
    border: solid grey;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    position: absolute;
    top: 3px;
    left: 9px;
    margin: 4px;
  }

  @media (max-width: 500px) {
    font-size: 0.8em;
  }
`;

const CheckboxParagraph = css`
  margin-left: 40px;
  margin-bottom: 0;
  font-size: 0.9em;
  text-align: left;
  font-style: italic;
`;

// Change Address container on final-frame step
const ChangeAddress = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: ${DEFAULT_SIZE.inputFieldWidth};
  width: 100%;
  margin: 10px auto 0 auto;

  p {
    margin-bottom: 0;
  }
  button {
    color: ${DEFAULT_COLORS.link};
    text-decoration: underline;
    margin: 0;
  }
`;

const AddressSubtext = css`
  width: 100%;
  margin-bottom: 5px;
`;

const LenderDropdown = css`
  background: #fff;
  margin-bottom: 10px;
  padding: 0 10px;
  position: absolute;
  width: 100%;

  p {
    margin-bottom: 0;
    border-bottom: 1px solid #ddd;
    padding: 5px;
    &:hover,
    &:focus {
      background: ${DEFAULT_COLORS.link};
      color: #fff;
      cursor: pointer;
    }
  }
`;

const FlexContainer = css`
  max-width: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
`;

export default {
  HeaderContainer,
  HeaderImage,
  AppWrapper,
  SurveyBackground,
  SurveyContainer,
  QuestionNumber,
  FormContainer,
  GlobalSealImg,
  ChoiceContainer,
  InputContainer,
  inputStyle,
  InputField,
  StyledTextArea,
  AddressContainer,
  StyledSelect,
  StyledZip,
  FinalInputContainer,
  HiddenContainer,
  QuestionTitle,
  InputTitle,
  Subtitle,
  TipContainer,
  QuestionContainer,
  QuestionBodyContainer,
  StyledSlider,
  SliderBox,
  StyledThumb,
  DisplayNumber,
  NumberContainer,
  SliderContainer,
  FillContainer,
  ProgressContainer,
  StatusBar,
  FooterNumberStatus,
  SubmitButton,
  BackButton,
  ButtonContainer,
  FooterContainer,
  DisclosureContainer,
  ModalButtonContainer,
  ModalButton,
  ModalBackground,
  ModalContainer,
  ExitButton,
  ModalInformation,
  BoldText,
  Text,
  FooterText,
  ImageList,
  HeadlineContainer,
  HeadlineHeader,
  SubHeader,
  SmallText,
  ErrorText,
  FinalDisclosureContainer,
  SpinnerContainer,
  Spinner,
  LoadingContainer,
  SpinnerMainContainer,
  CongratsHolder,
  CheckboxWrapper,
  CheckboxContainer,
  CheckboxLabel,
  CheckboxParagraph,
  ChangeAddress,
  AddressSubtext,
  LenderDropdown,
  FlexContainer,
};
